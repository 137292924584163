// Generated by Framer (38f2e7f)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {we22BRTRI: {hover: true}};

const serializationHash = "framer-Jf7t1"

const variantClassNames = {we22BRTRI: "framer-v-1hm3yvr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "we22BRTRI", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1hm3yvr", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"we22BRTRI"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-e21d78c6-9a46-4cbd-aadc-ebd33ec712f0, rgb(237, 238, 240))", borderBottomLeftRadius: 60, borderBottomRightRadius: 60, borderTopLeftRadius: 60, borderTopRightRadius: 60, ...style}} {...addPropertyOverrides({"we22BRTRI-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1xmglu9-container"} layoutDependency={layoutDependency} layoutId={"oexH2TTim-container"}><Phosphor color={"var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(23, 23, 23))"} height={"100%"} iconSearch={"House"} iconSelection={"XLogo"} id={"oexH2TTim"} layoutId={"oexH2TTim"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Jf7t1.framer-4q0tho, .framer-Jf7t1 .framer-4q0tho { display: block; }", ".framer-Jf7t1.framer-1hm3yvr { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 1px; height: min-content; justify-content: center; overflow: hidden; padding: 10px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-Jf7t1 .framer-1xmglu9-container { flex: none; height: 22px; position: relative; width: 22px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Jf7t1.framer-1hm3yvr { gap: 0px; } .framer-Jf7t1.framer-1hm3yvr > * { margin: 0px; margin-left: calc(1px / 2); margin-right: calc(1px / 2); } .framer-Jf7t1.framer-1hm3yvr > :first-child { margin-left: 0px; } .framer-Jf7t1.framer-1hm3yvr > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"wl33A_dod":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerA0TA3YneW: React.ComponentType<Props> = withCSS(Component, css, "framer-Jf7t1") as typeof Component;
export default FramerA0TA3YneW;

FramerA0TA3YneW.displayName = "Button/Twitter";

FramerA0TA3YneW.defaultProps = {height: 42, width: 42};

addFonts(FramerA0TA3YneW, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})